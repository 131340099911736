import styled, { css } from 'styled-components';
import Button from '../../../Button/Button';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';

export const StyledLoadMoreButton = styled(Button)`
  margin: 0px auto;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 1fr));
  grid-column-gap: ${spacer(25)};
  grid-row-gap: ${spacer(50)};

  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
      grid-gap: ${spacer(100)};
    `
  )}
`;
