import styled, { css } from 'styled-components';
import { spacer } from 'theme';
import { StyledLinkContentProps } from './ContentfulPageLink';

export const StyledLinkContent = styled.span<StyledLinkContentProps>`
  display: flex;
  align-items: center;
  gap: ${spacer(25)};

  ${({ iconPosition }) =>
    iconPosition?.toLowerCase() === 'before' &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}

  ${({ contentGap }) =>
    contentGap !== undefined &&
    css`
      gap: ${spacer(contentGap)};
    `}
`;
