import { CONTENTFUL_TYPES } from 'components/Contentful/ContentfulFields/types';
import { Entry } from 'contentful';
import {
  IBanner,
  IBlockImage,
  IEntry,
  IPageCategory,
  IPageCms,
  IPageContent,
  IPageExternal,
  IPageHome,
  IPagePdp,
  IPagePlp,
} from './ContentfulTypes';

export type ContentLink = IPageCms | IPageExternal | IPagePdp | IPagePlp | IPageContent | undefined;

export type ContentPage = IPageHome | ContentLink;

export type ContentfulBuildVariable = Record<'brand', string>;

export const isBlockImage = (content: IBlockImage | IPageContent): content is IBlockImage =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.BLOCK_IMAGE;

export const isPageContent = (content: IEntry): content is IPageContent =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_CONTENT;

export const isPageExternal = (content: IEntry): content is IPageExternal =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_EXTERNAL;

export const isPagePdp = (content: IEntry): content is IPagePdp =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_PDP;

export const isPagePlp = (content: IEntry): content is IPagePlp =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_PLP;

export const isPageCms = (content: IEntry): content is IPageCms =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_CMS;

export const isPageCategory = (content: IEntry): content is IPageCategory =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_CATEGORY;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPageHome = (content: IEntry | Entry<any>): content is IPageHome =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.PAGE_HOME;

export const isBanner = (content?: IEntry): content is IBanner =>
  content?.sys?.contentType.sys.id === CONTENTFUL_TYPES.BANNER;
