import useRouter from 'hooks/useRouter';
import { ReactNode } from 'react';
import { ContentLink, isPageExternal } from 'types/Contentful';
import { IPageCms, IPageContent, IPagePdp, IPagePlp } from 'types/ContentfulTypes';
import { IconPosition, Spacer } from '../../../../constants/styling';
import getBuildVariables from '../../../../utils/ContentfulBuildVariablesUtil';
import { buildPageUrl, matchBrand } from '../../../../utils/contentfulUtil';
import { isExternalUrl } from '../../../../utils/urlUtil';
import Button, { ButtonProps } from '../../../Button/Button';
import Icon, { IconProps } from '../../../Icon/Icon';
import Link from '../../../Link/Link';
import { CALL_TO_ACTION_TYPES, CONTENTFUL_TYPES } from '../types';
import { StyledLinkContent } from './ContentfulPageLink.styled';

export interface StyledLinkContentProps {
  contentGap?: Spacer;
  iconPosition?: IconPosition;
}

function getDestinationField(link: ContentLink) {
  if (link && isPageExternal(link)) {
    return link.fields.url;
  } else {
    return (link as IPageCms | IPagePdp | IPagePlp | IPageContent | undefined)?.fields.slug;
  }
}

interface ContentfulPageLinkProps extends StyledLinkContentProps {
  buttonProps?: ButtonProps;
  children?: ReactNode;
  className?: string;
  hideIfInvalid?: boolean;
  iconComponent?: ReactNode;
  iconProps?: IconProps;
  label?: ReactNode;
  link?: ContentLink;
  type?: string;
}

const ContentfulPageLink = ({
  buttonProps,
  children,
  className,
  contentGap,
  hideIfInvalid,
  iconComponent,
  iconPosition,
  iconProps,
  label,
  link,
  type,
}: ContentfulPageLinkProps) => {
  const { locale } = useRouter();

  const content = children || label;
  const icon = iconComponent || (!!iconProps && <Icon {...iconProps} />);
  const linkContent = icon ? (
    <StyledLinkContent contentGap={contentGap} iconPosition={iconPosition}>
      {content}
      {icon}
    </StyledLinkContent>
  ) : (
    content
  );

  if (!link?.fields) {
    if (hideIfInvalid) {
      return null;
    }

    return <div className={className}>{linkContent}</div>;
  }

  const destination = getDestinationField(link);
  const contentModel = link.sys.contentType.sys.id;
  const isExternal = contentModel === CONTENTFUL_TYPES.PAGE_EXTERNAL;
  const href =
    !!destination && (isExternalUrl(destination) ? destination : buildPageUrl(contentModel, destination, locale));
  const pageHasMarketChannelAndSlug = matchBrand(getBuildVariables())(link);

  const externalProps = {
    rel: isExternal ? 'noopener noreferrer' : '',
    target: isExternal ? '_blank' : '_self',
  };

  if (pageHasMarketChannelAndSlug && href) {
    if (type === CALL_TO_ACTION_TYPES.BUTTON) {
      return (
        <Link className={className} url={href} {...externalProps}>
          <Button {...buttonProps}>{linkContent}</Button>
        </Link>
      );
    }

    return (
      <Link className={className} url={href} {...externalProps}>
        {linkContent}
      </Link>
    );
  }

  if (hideIfInvalid) {
    return null;
  }

  return <div className={className}>{linkContent}</div>;
};

export default ContentfulPageLink;
