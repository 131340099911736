import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { GROUPING_PRODUCTS_TYPES } from '../types';
import ContentfulProductCarousel from '../ContentfulProductCarousel/ContentfulProductCarousel';
import ContentfulProductTiles from '../ContentfulProductTiles/ContentfulProductTiles';
import { IGroupingProducts } from 'types/ContentfulTypes';

interface ContentfulGroupingProductsProps {
  content: IGroupingProducts;
}

const ContentfulGroupingProducts = ({ content, ...props }: ContentfulGroupingProductsProps) => {
  if (!content?.fields) {
    return null;
  }

  const { type } = content.fields;

  if (type === GROUPING_PRODUCTS_TYPES.CAROUSEL) {
    return <ContentfulProductCarousel content={content} {...props} />;
  }

  if (type === GROUPING_PRODUCTS_TYPES.TILE) {
    return <ContentfulProductTiles content={content} {...props} />;
  }

  return null;
};

export default memo(ContentfulGroupingProducts, isEqual);
